import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import PartnerInfo from "./PartnerInfo";
import { Ansprechpartner } from "../interfaces/Config";
import { iframeAtom } from "../atom/Atoms";
import { SMBreakPoint, XSBreakPoint, MDBreakPoint } from "../Theme";

interface PartnerProps {
  img?: string;
  ansprechpartner: Ansprechpartner;
  insideHeader?: boolean;
}

const Wrapper = styled(Grid)`

`;

const InnnerWrapper = styled(Grid)`
  display: none;
`;


const Card = styled(Grid)`
  width: 300px;
  height: 200px;
  position: fixed;
  right: 0;
  padding-top: 50px;
  padding-left: 50px;
  bottom: 190px;
  z-index: 5;
  border-radius: ${(props: any) => props.theme.partnerBorder};
`;

const CardContentScroll = styled(Grid)`
  position: absolute;
  right: -250px;
  width: 300px !important;
  height: 200px;
  visibility: hidden;
  z-index: 5;

  :hover {
    visibility: visible;
    transition: 1.5s;
    background: rgba(255, 255, 255, 0.8);
    right: 0;
  }

  @media (max-width: 760px) {
    height: 180px;
  }
`;

const WrapperHeader = styled(Grid)`

`;

const CardHeader = styled(Grid)`
  position: absolute;
  right: 0 !important;
  top: 10%;
  padding: 50px 0 0 20px;
  width: 23vw;
  max-width: 370px;
  height: 220px;
`;

const CardContentHeader = styled(Grid)`
  position: absolute;
  right: 0;
  width: 290px !important;
  height: fit-content;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  border-radius: ${(props: any) => props.theme.partnerBorder}!important;

  @media (max-width: 1205px) {
    width: 250px !important;
  }
  @media (max-width: 1130px) {
    width: 250px !important;
  } 
  @media (max-width: 989px) {
    width: 280px !important;
  }
`;


const CardHeaderMobile = styled(Grid)`
  position: absolute;
  overflow: hidden;
  right: 0 !important;
  top: 10%;
  padding: 50px 0 50px 70px;
  width: 23vw;
  max-width: 370px;
  height: 220px;
  @media (max-width: 1220px) {
    width: 22vw;
  }
  @media (max-width: 989px) {
    width: 29vw;
  }
  @media (max-width: 768px) {
    top: 0;
    width: 35vw;
    padding: 50px 0 50px 90px;
  }
  @media (max-width: 650px) {
    padding: 40px 0 50px 90px;
  }  
  @media (max-width: 375px) {
    top: -10px;
    padding: 40px 0 50px 100px;
  }
`;

const CardContentHeaderMobile = styled(Grid)`
  position: absolute;
  right: -200px;
  width: 250px !important;
  height: 200px;
  visibility: hidden;
  z-index: 5;
 
  :hover {
    visibility: visible;
    transition: 1.5s;
    background: rgba(255, 255, 255, 0.8);
    right: 0;
  }

  @media (max-width: 768px) {
    height: 250px !important;
  } 
  @media (max-width: 650px) {
    right: -150px;
    height: 200px !important;
    width: 200px !important;
  }  
  @media (max-width: 320px) {
    right: -150px;
    height: 150px;
    width: 185px !important;
    height: 180px !important;
  }

`;

/*
 * TODO: Bei Mobil nicht anzeigen, bei Desktop anzeigen
 * und ab bestimmter scrollheight anders anzeigen
 */
export default function Partner({ img, ansprechpartner, insideHeader }: PartnerProps): any {
  const [iframe] = useRecoilState(iframeAtom);
  const [actualWidth, setActualWidth] = useState(window.innerWidth);
  const checkpoint = 450;
  const mediaQueryDesktop = window.matchMedia("(min-width: 993px)").matches;
  if (mediaQueryDesktop) {
    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      const opacity: number = currentScroll <= checkpoint ? 0 : 1;
      document.getElementById("WrapperOnScroll")!.style.display = "block";
      document.getElementById("WrapperOnScroll")!.style.opacity = String(opacity);
    });
  }

  const PartnerOnScroll = () => {
    if (!iframe) {
      return (
        <InnnerWrapper id="WrapperOnScroll">
          <Card>
            <CardContentScroll
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              id="slide"
            >
              <PartnerInfo src={img} ansprechpartner={ansprechpartner} />
            </CardContentScroll>
          </Card>
        </InnnerWrapper>
      )
    } else return <InnnerWrapper id="WrapperOnScroll"></InnnerWrapper>;
  }

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setActualWidth(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions)

  }, []);

  return (
    <Wrapper>
      {insideHeader ? (
        <WrapperHeader>
          {actualWidth > 768 ? (
            <CardHeader>
              <CardContentHeader
                container
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                id="slide"
              >
                <PartnerInfo src={img} ansprechpartner={ansprechpartner} />
              </CardContentHeader>
            </CardHeader>
          ) : (
            <CardHeaderMobile>
              <CardContentHeaderMobile
                container
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                id="slide"
              >
                <PartnerInfo src={img} ansprechpartner={ansprechpartner} />
              </CardContentHeaderMobile>
            </CardHeaderMobile>
          )}
        </WrapperHeader>
      ) : (
        <PartnerOnScroll />
      )}
    </Wrapper>
  );
}
