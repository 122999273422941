import hookUKV from "./assets/hook_ukv.svg";
import hookVKB from "./assets/hook_vkb.svg";

export const colors = {
  white: "#FFFFFF",
  lighterBlue: "#EBF1F7",
  lightBlue: "#E7EFFB",
  middleDarkBlue: "#0D57A6",
  darkBlue: "#024589",

  offWhite: "rgba(255,255,255,0.92)",

  lighterGrey: "#F5F5F5",
  lightGreen: "#B4CF35",
  lightGrey: "#0000001A",
  middleGrey: "#00000029",
  middleDarkGrey: "#666666",
  grey: "#0000004D",
  darkGrey: "#707070",
  darkerGrey: "#333333",

  black: "#191919",
  darkBlack: "#000000",

  red: "#FF1010",
};

export const Fonts = {
  // Font-size
  fontSizeH1: "50px",
  fontSizeH2: "36px",
  fontSizeH3: "28px",

  fontSizeBig: "30px",
  fontSizeMedium: "26px",
  fontSizeText: "20px",
  fontSizeTextSmall: "16px",
  fontSizeTextExtraSmall: "14px",

  // FontFamily
  ubuntuRegular: "UbuntuRegular",
  ubuntuMedium: "UbuntuMedium",
  ubuntuBold: "UbuntuBold",

  // Font-Weight
  fontWeightBold: 700,
  fontWeightNormal: "normal",
  fontWeight600: 600,
  fontWeightMedium: 500,
  fontWeightLighter: "lighter",

  // Font-style
  fontStyleItalic: "italic",
  fontStyleNormal: "normal",

  // Font Mobile
  fontH1Mobile: "normal normal 600 32px/41px Ubuntu",
  fontIntroTextMobile: "normal normal 600 16px/24px Ubuntu",
  fontTextLinkSmallNavMobile: "normal normal 600 16px/32px Ubuntu",

  // Mobile Fonts
  fontFooterLinksMobile: "normal normal 600 16px/32px Ubuntu, SemiBold",
  fontTextMobile: "normal normal 500 16px/24px Ubuntu, Medium",

  // LineSpacing
  lineSpacingH1: "55px",
  lineSpacingH2: "44px",
  lineSpacingFooter: "36px",
  lineSpacingH3MainNav: "34px",
  lineSpacingText: "27px",
  lineSpacingTextRedSmall: "22px",

  // Width general
  ContentWidth: "1300px",
  innerContentWidth: "1000px",
};

export const vkb = {
  icon: hookVKB,
  h1Color: colors.darkBlue,
  h1Weight: Fonts.fontWeightBold,

  // header
  logoBorder: "0px 0px 0px 0px",
  titleWeight: Fonts.fontWeightBold,

  // partner
  badgeBackground: colors.darkBlue,
  partnerIconColor: colors. white,
  partnerTitleWeight: 700,
  partnerTitleColor: colors.darkBlue,
  partnerNameColor: colors.black,
  partnerNameWeight: Fonts.fontWeightBold,
  partnerLinkColor: colors.darkBlue,
  partnerLinkWeight: Fonts.fontWeightMedium,
  partnerBorder: "0px",

  // slider
  titleColor: colors.darkBlue,
  textWeight: "bold",
  iconBackground: colors.lightGreen,
  cardBackground: colors.white,
  containerBackground: colors.lightBlue,
  topSpace: "25px",

  // Benefits
  textColorBlack: "#191919",
  tileIconBackground: "#B4CF35",
  iconWidth: "30px",
  iconHeight: "30px",
  iconContainerHeight: "inherit",

  // Footer
  textColorFooter: "#024589",
  footerBackground: "#00000029",
  textWeightFooter: "500",
  // Text-Weight
  textWeightTitle: "700",
  textWeightSubTitle: "500",
  // Font-size
  fontSizeTitle: "36px",
  fontSizeTitleSmall: "26px",
  fontSizeSubtitle: "20px",
  fontSizeText: "16px",
  fontSizeFooter: "14px",
  buttonBorderRadius: "0",
  buttonFontSize: "20px",
  buttonFontSizeMobile: "14px",
  buttonBorderPaddingMobile: "10px 20px",
  buttonBorderPadding: "16px 40px",
  buttonBackgroundColor: "#B4CF35",
  buttonColor: "#024589",
};

export const ukv = {
  icon: { hookUKV },
  h1Color: colors.darkerGrey,
  h1Weight: Fonts.fontWeightLighter,

  // header
  logoBorder: "15px 15px 0px 0px",
  titleWeight: 400,

  // partner
  badgeBackground: colors.offWhite,
  partnerIconColor: colors. darkBlue,
  partnerTitleWeight: 400,
  partnerTitleColor: colors.darkerGrey,
  partnerLinkColor: colors.middleDarkBlue,
  partnerLinkWeight: Fonts.fontWeightMedium,
  partnerBorder: "20px 0 0 20px",

  // slider
  titleColor: colors.darkerGrey,
  textWeight: "lighter",
  iconBackground: colors.middleDarkBlue,
  cardBackground: colors.white,
  containerBackground: colors.lighterGrey,
  topSpace: "20px",

  // Benefits
  textColorBlack: "#191919",
  tileIconBackground: "none",
  iconWidth: "50px",
  iconHeight: "50px",
  iconColor: "blue",
  iconContainerHeight: "unset",

  // footer
  textColorFooter: "#0D57A6",
  footerBackground: "#EBF1F7",
  textWeightFooter: "500",
  // Text-Weight
  textWeightTitle: "400",
  // Font-size
  fontSizeTitle: "30px",
  fontSizeTitleSmall: "26px",
  fontSizeSubtitle: "20px",
  fontSizeText: "16px",
  fontSizeFooter: "14px",
  buttonFontSize: "16px",
  buttonFontSizeMobile: "14px",
  buttonBorderPaddingMobile: "8px 15px",
  buttonBorderRadius: "50px",
  buttonBorderPadding: "11px 15px",
  buttonBackgroundColor: "#EA8F31",
  buttonColor: "#fff",
};

export const XSBreakPoint = "990px";
export const SMBreakPoint = "1010px";
export const MDBreakPoint = "1280px";
