import { ButtonProps } from "@material-ui/core";
import React from "react";
import { CustomButton, CustomHref } from "./style";

type NavbuttonProps = {
  text: string;
  link?: string;
} & ButtonProps;

export default function Index({ onClick, text, link }: NavbuttonProps) {
  return (
    <CustomButton onClick={onClick && onClick}>
      {link && <CustomHref href={link}> {text}</CustomHref>}
      {!link && text}
    </CustomButton>
  );
}
