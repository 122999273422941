import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import Partner from "../Partials/Partner";
import { XSBreakPoint, SMBreakPoint, MDBreakPoint } from "../Theme";
import { ukvData, vkbData } from "../mocks/mockContent";
import { Config } from "../interfaces/Config";
import ImageHandler from "../assets/images";

interface HeaderProps {
  config: Config;
}

const Wrapper = styled(Grid)``;

const Hero = styled.div`
  background-position: 0 40%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
  height: 45vw;
  @media (max-width: 560px) {
    height: 60vw !important; 
  } 
`;

const Group = styled.img`
  width: 220px;
  object-fit: contain;
  max-height: 120px;
  position: absolute;
  left: 20vw;
  top: 0;
  background-color: #ffffff;

  @media (max-width: ${XSBreakPoint}) {
    left: 20px;
  } 
  @media (max-width: 550px) {
    width: 140px;
  }
`;

const CompanyName = styled.p`
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px 10px 15px 10px;
  background: white;
  position: absolute;
  bottom: 2vw;
  margin: 0;
  right: 15%;
  justify-content: center;
  align-items: center;
  //border-radius: ${(props: any) => props.theme.logoBorder} !important;
  z-index: 10;
  box-shadow: -8px -8px 8px 0px rgba(0, 0, 0, 0.27);
  color: red;
  font-size: 20px;
  text-transform: uppercase;

  @media (max-width: ${XSBreakPoint}) {
    bottom: 0;
  }
`;

const Logo = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  min-width: 100px;
  object-fit: contain;
  padding: 3px;
  background: white;
  bottom: 2vw;
  height: 60px;
  right: 15%;
  border-radius: ${(props: any) => props.theme.logoBorder};
  @media (max-width: ${XSBreakPoint}) {
    bottom: 0;
  } 
  @media (max-width: 560px) {
    height: 50px;
  } 
  @media (max-width: 375px) {
    height: 45px;
  }
`;

const Title = styled.h1`
  display: none;
  text-shadow: 0 0 5px #363636;
  font-size: 34px !important;
  @media (max-width: 1175px) {
    font-size: 32px !important;
    width: 700px;
  } 
  @media (max-width: 1090px) {
    font-size: 30px !important;
  } 
  @media (max-width: 1050px) {
    font-size: 26px !important;
  }
  @media (min-width: ${XSBreakPoint}) {
    display: block;
    color: white;
    box-shadow: unset;
    align-self: center;
    margin: 0 auto;
    background: unset;
    max-width: 58%;
    font-size: calc(1.525rem + ((1vw - 7.68px) * 2.0833));
    line-height: calc(1.625rem + ((1vw - 7.68px) * 2.0833));
    text-align: center;
    font-weight: ${(props: any) => props.theme.titleWeight};
    width: 300px;
  }
  @media (min-width: ${SMBreakPoint}) {
    width: 470px;
  }
  @media (min-width: ${MDBreakPoint}) {
    width: 780px;
  }
`;

const TitleMobile = styled.h1`
  display: block;
  margin: unset;
  padding: 30px 67px;
  line-height: calc(1.625rem + ((1vw - 7.68px) * 2.0833));
  color: #024589;
  text-align: center;
  box-shadow: 0px 0px 10px #00000029;
  font-size: calc(1.625rem + ((1vw - 7.68px) * 2.0833));
  background: white;

  @media (min-width: ${XSBreakPoint}) {
    display: none;
  }
`;

const TriangleContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0px;

  div {
    border-width: 2vw 2vw 0 2vw;
    border-color: transparent white white white;
  }
`;

const WhiteBox = styled.div`
  flex-grow: 1;
  background: white;
  box-shadow: -8px -8px 8px 0px rgba(0, 0, 0, 0.27);
`;

const Triangle = styled.div`
  display: none;

  @media (min-width: ${XSBreakPoint}) {
    display: block;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: white transparent transparent transparent;
    z-index: 10;
  }
`;

const TriangleMobile = styled.div`
  display: block;
  width: 0;
  height: 0;
  margin: 0 auto;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: white transparent transparent transparent;

  @media (min-width: ${XSBreakPoint}) {
    display: none;
  }
`;

export default function HeaderComponent({ config }: HeaderProps) {
  // @ts-ignore
  const img = ImageHandler[config.hintergrundbild] as string;

  return (
    <div>
      <Wrapper>
        <Hero id="hero" style={{ backgroundImage: `url(${img})` }}>
          <Group
            src={
              config.isukvdesign
                ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8dxKzTlrYeumWMhT4Viz2iPkv5uRx8cIeOA&usqp=CAU"
                : "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/VKBayern_logo.svg/1200px-VKBayern_logo.svg.png"
            }
          />
          <Partner
            img={config.isukvdesign ? ukvData.partnerImg : vkbData.partnerImg}
            ansprechpartner={config.ansprechpartner}
            insideHeader
          />
          <Title>
            {config.isukvdesign ? ukvData.headerTitle : vkbData.headerTitle}
          </Title>
          {config.logo &&
            <Logo src={config.logo} alt="logo" />
          }
          <TriangleContainer>
            <WhiteBox className="column left" />
            <Triangle />
            <WhiteBox className="column right" />
          </TriangleContainer>
        </Hero>
        <TitleMobile>
          {config.isukvdesign ? ukvData.headerTitle : vkbData.headerTitle}
        </TitleMobile>
        <TriangleMobile />
      </Wrapper>
    </div>
  );
}