export const footerUKV = {
  links: [
    {
      to: "https://www.ukv.de/content/service/kontakt/",
      text: "Kontakt",
    },
    {
      to: "https://www.ukv.de/content/ueber_uns/",
      text: "Über uns",
    },
    {
      to: "https://www.ukv.de/content/ueber_uns/karriere/",
      text: "Karriere",
    },
    {
      to: "https://www.vkb.de/content/ueber-uns/presse/pressemitteilungen/",
      text: "Presse",
    },
    {
      to: "https://www.ukv.de/content/ueber_uns/datenschutz/",
      text: "Datenschutz-Hinweise",
    },
    {
      to: "https://www.ukv.de/content/ueber_uns/compliance/",
      text: "Compliance-Hinweise",
    },
    {
      to: "https://www.ukv.de/content/ueber_uns/impressum/",
      text: "Impressum",
    },
  ],
  copyright:
    "© 2022 Union Krankenversicherung AG - Private Krankenversicherung",
};

export const footerVKB = {
  links: [
    {
      to: "https://www.vkb.de/content/ueber-uns/impressum/",
      text: "Impressum",
    },
    {
      to: "https://www.vkb.de/content/ueber-uns/unternehmen/datenschutz-compliance/datenschutz/",
      text: "Datenschutz",
    },
    {
      to: "https://www.vkb.de/content/ueber-uns/unternehmen/datenschutz-compliance/compliance/",
      text: "Compliance-Hinweise",
    },
  ],
  copyright: "© 2022 Versicherungskammer Bayern",
};
