import { Button } from "@material-ui/core";
import styled from "styled-components";

export const CustomButton = styled(Button)`
  color: ${(props: any) => props.theme.buttonColor} !important;
  font-weight: bold !important;
  text-transform: unset !important;
  font-size: ${(props: any) => props.theme.buttonFontSize} !important;  
  padding: ${(props: any) => props.theme.buttonBorderPadding} !important;  
  border-radius: ${(props: any) => props.theme.buttonBorderRadius} !important;  
  background-color: ${(props: any) => props.theme.buttonBackgroundColor} !important;
  
  @media (max-width: 480px){
    font-size: ${(props: any) => props.theme.buttonFontSizeMobile} !important;
    padding: ${(props: any) => props.theme.buttonBorderPaddingMobile} !important;
  }
`;

export const CustomHref = styled.a`
  text-decoration: none;
  color: ${(props: any) => props.theme.buttonColor} !important;
`;
