import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Fonts, XSBreakPoint } from "../Theme";
import hookUKV from "../assets/hook_ukv.svg";
import hookVKB from "../assets/hook_vkb.svg";
import { ukvData, vkbData } from "../mocks/mockContent";

interface BenefitsProps {
  isukvdesign: boolean;
}

const Wrapper = styled(Grid)`
  margin: 50px 0;
`;

const TileContainer = styled(Grid)`
  max-width: ${Fonts.ContentWidth};
  margin: 0 auto;
  @media (max-width: ${XSBreakPoint}) {
    height: auto;
  }
`;

const StyledTile = styled(Grid)`
  background: ${(props: any) => props.theme.containerBackground};
  color: ${(props: any) => props.theme.TileTextColor};
  margin: 10px !important;
  min-width: 310px;
  height: 181px;
  @media (max-width: ${XSBreakPoint}) {
    margin: 2em 25px !important;
    min-width: 95%;
    height: 140px;
  } 
  @media (max-width: 460px) {
    height: 160px;
  }
`;

const Title = styled(Grid)`
  font-size: ${(props: any) => props.theme.fontSizeTitle};
  color: ${(props: any) => props.theme.titleColor};
  font-weight: ${(props: any) => props.theme.textWeightTitle};
  width: 100%;
  margin: 0 50px 50px 50px;
  
  text-align: center;
  @media (max-width: ${XSBreakPoint}) {
    font-size: ${(props: any) => props.theme.fontSizeTitleSmall};
  }
`;

const Subtitle = styled.p`
  font-size: ${(props: any) => props.theme.fontSizeSubtitle};
  color: ${(props: any) => props.theme.textColorBlack};
  font-weight: ${(props: any) => props.theme.textWeightSubTitle};
  text-align: center;
  height: 50px;
  @media (max-width: ${XSBreakPoint}) {
    height: 20px;
  }
  @media (max-width: 460px) {
    height: 45px;
  }
`;

const Text = styled.p`
  font-size: ${(props: any) => props.theme.fontSizeText};
  color: ${(props: any) => props.theme.textColorBlack};
  text-align: center;
  font-weight: 500;
  @media (max-width: ${XSBreakPoint}) {
    top: -40px;
  }
`;

const IconWrapper = styled(Grid)``;

const IconBackground = styled.div`
  background: ${(props: any) => props.theme.tileIconBackground};
  border-radius: 50%;
  margin: 0 auto;
  align-items: center;
  display: inline-block;
  height: 54px;
  width: 54px;
  position: relative;
  top: -25px;
  @media (max-width: ${XSBreakPoint}) {
    height: 42px;
    width: 42px;
  }
`;

const Icon = styled.img`
  width: ${(props: any) => props.theme.iconWidth};
  height: ${(props: any) => props.theme.iconHeight};
  color: #0000ff;
  margin: 0 auto;
  display: block;
  height: ${(props: any) => props.theme.iconContainerHeight};
`;

const Content = styled.div`
  padding: 20px;
  position: relative;
  top: -50px;
  @media (max-width: ${XSBreakPoint}) {
    top: -50px;
  }
`;

export function BenefitsComponent({ isukvdesign }: BenefitsProps) {
  return (
    <Wrapper container>
      <Title>
        {isukvdesign ? ukvData.benefitsTitle : vkbData.benefitsTitle}
      </Title>
      <TileContainer container justifyContent="center">
        {(isukvdesign ? ukvData.benefits : vkbData.benefits).map((benefit) => (
          <StyledTile
            container
            item
            key={benefit.id}
            justifyContent="center"
            sm={12}
            md={3}
          >
            <IconWrapper container justifyContent="center">
              <IconBackground>
                <Icon src={isukvdesign ? hookUKV : hookVKB} />
              </IconBackground>
            </IconWrapper>

            <Content>
              <Subtitle>{benefit.title}</Subtitle>
              <Text>{benefit.text}</Text>
            </Content>
          </StyledTile>
        ))}
      </TileContainer>
    </Wrapper>
  );
}
