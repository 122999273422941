import { CardContent, Grid } from "@material-ui/core";
import styled from "styled-components";
import { XSBreakPoint } from "../../Theme";

export const Container = styled.div`
  @media (max-width: ${XSBreakPoint}) {
    background: ${(props: any) => props.theme.containerBackground};
    padding-bottom:10% ;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: ${(props: any) => props.theme.textWeightTitle};
  font: ${(props: any) => `normal normal 22px/32px${props.theme.textFontFamily}`};
  letter-spacing: 0;
  color: ${(props: any) => props.theme.titleColor};
  margin: 0 30% 1% 30%;
  padding-top: 1%;
  opacity: 1;

  @media (max-width: ${XSBreakPoint}) {
    margin: 0 0 1% 0;
    font: normal normal bold 22px/32px Ubuntu;
    font-weight: ${(props: any) => props.theme.textWeightTitle};
  }
`;

export const Subtitle = styled.h2`
text-align: center;
//font: normal normal 28px/46px Ubuntu;
font-weight: ${(props: any) => props.theme.textWeightTitle};
font-size: ${(props: any) => props.theme.fontSizeSubtitle}
margin-top: 2%;
color: ${(props: any) => props.theme.titleColor};

@media(max-width: ${XSBreakPoint}){
  margin: 1% 0 1% 0;
  //font: normal normal 18px/46px Ubuntu;
  font-size: ${(props: any) => props.theme.fontSizeSubtitle}
  font-weight: ${(props: any) => props.theme.textWeightTitle};
  color: ${(props: any) => props.theme.titleColor};
}
`;

export const Text = styled.p`
  position: relative;
  top: -2em;
  text-align: center;
  //font: normal normal normal 20px/30px Ubuntu;
  font-size: ${(props: any) => props.theme.fontSizeText};
  letter-spacing: 0;
  color: ${(props: any) => props.theme.textColorBlack};
  opacity: 1;
  margin: 3em 25% 0 25%;

  @media (max-width: ${XSBreakPoint}) {
    margin: 3em 5% 0 5%;
    //font: normal normal normal 16px/22px Ubuntu;
    font-size: ${(props: any) => props.theme.fontSizeText};
  }
`;

export const StyledTile = styled.div`
  margin: 0 0.5em;
  height: 17em;
`;

export const IconWrapper = styled(Grid)``;

export const IconBackground = styled.div`
  display: inline-block;
  align-items: center;
  height: 50px;
  position: relative;
  top: ${(props: any) => props.theme.topSpace};
`;

export const Icon = styled.img`
  color: blue;
  margin: 0 auto;
  width: 65px;
  display: block;
  height: ${(props: any) => props.theme.iconContainerHeight};

  .MuiSvgIcon-root {
    font-size: 3rem;
    width: 1.5em;
    height: 1.5em;
    background-color: ${(props: any) => props.theme.iconBackground};
    border-radius: 50%;
  }
`;

export const CardSubtitle = styled.p`
  height: auto;
  display: inline-block;
  word-break: break-word;
  position: relative;
  width: 100%;
  padding-top: 5%;
  margin-bottom: 0;
  text-align: center;
  font: normal normal bold 20px/26px Ubuntu;
`;

export const CardText = styled.p`
  position: relative;
  display: inline-block;
  word-break: break-word;
  text-align: center;
  font: normal normal normal 16px/26px Ubuntu;
  letter-spacing: 0;
  color: ${(props: any) => props.theme.textColorBlack};
  opacity: 1;
`;

export const CustomCardContent = styled(CardContent)`
  overflow: hidden;
  height: 12em;
  text-align: center;
  background-color: ${(props: any) => props.theme.containerBackground};
  @media (max-width: ${XSBreakPoint}) {
    background-color: ${(props: any) => props.theme.cardBackground};
  }
`;
