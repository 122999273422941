// eslint-disable-next-line no-unused-vars
const headerGroup =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8dxKzTlrYeumWMhT4Viz2iPkv5uRx8cIeOA&usqp=CAU';
const testIMG =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/How_to_use_icon.svg/1200px-How_to_use_icon.svg.png';

export const vkbData = {
  firmenName: 'Firmenname',
  headerTitle:
    'Ihre bestehende betriebliche Krankenversicherung (bKV) individuell anpassen & optimieren',
  headerGroup: '',
  headerHeroImg:
    'https://thumbs.dreamstime.com/b/%C3%A4lterer-mann-der-dem-laptopl%C3%A4cheln-arbeitet-20855483.jpg',
  partnerImg: testIMG,
  partnerName: 'Max Mustermann',
  partnerTel: '017200000',
  partnerEmail: '123@gmail.com',
  sliderTitle: 'Optionen für Ihre betriebliche Krankenversicherung der ',
  sliderSubtitle: 'Die betriebliche Krankenversicherung - Ein Gewinn für alle',
  sliderText:
    'Ihr Arbeitgeber hat für Sie die betriebliche Krankenversicherung abgeschlossen.' +
    'Sie können den darin enthaltenen Versicherungsschutz hier bequem per Online-Abschluss erweitern und folgende Vorteile genießen:',
  videoTitle: '',
  videourl:
    'https://video-test-bucket-mp4.s3.eu-central-1.amazonaws.com/SampleVideo_1280x720_10mb.mp4',
  iFrameTitle: 'Berechnen Sie Ihren Beitrag',
  benefitsTitle: 'Ihre Vorteile',
  benefits: [
    {
      id: 1,
      title: 'Individualität',
      text: 'Erweitern Sie Ihre Leistungen nach Ihren individuellen Bedürfnissen',
    },
    {
      id: 2,
      title: 'Keine Gesundheitsfragen und Wartezeiten',
      text: 'Bei arbeitgebervollfinanzierter Variante',
    },
    {
      id: 3,
      title: 'Garantierte Mehrleistungen',
      text: 'Ergänzend zu den Leistungen der gesetztlichen Krankenversicherung',
    },
  ],
};

export const ukvData = {
  firmenName: 'Firmenname',
  headerTitle:
    'Ihre bestehende betriebliche Krankenversicherung (bKV) individuell anpassen & optimieren',
  headerGroup: '',
  headerHeroImg:
    'https://thumbs.dreamstime.com/b/%C3%A4lterer-mann-der-dem-laptopl%C3%A4cheln-arbeitet-20855483.jpg',
  partnerImg: testIMG,
  partnerName: 'Max Mustermann',
  partnerTel: '017200000',
  partnerEmail: '123@gmail.com',
  sliderTitle: 'Optionen für Ihre betriebliche Krankenversicherung der',
  sliderSubtitle:
    'Die betriebliche Krankenversicherung - Ein Mehrwert für Sie mit individuellen Gestaltungsmöglichkeiten',
  sliderText:
    'Ihr Arbeitgeber hat für Sie die betriebliche Krankenversicherung abgeschlossen.' +
    'Sie können den darin enthaltenen Versicherungsschutz hier bequem per Online-Abschluss erweitern und folgende Vorteile genießen:',
  videoTitle: '',
  videourl:
    'https://video-test-bucket-mp4.s3.eu-central-1.amazonaws.com/SampleVideo_1280x720_10mb.mp4',
  iFrameTitle: 'Berechnen Sie Ihren Beitrag',
  benefitsTitle: 'Ihre Vorteile',
  benefits: [
    {
      id: 1,
      title: 'Individualität',
      text: 'Erweitern Sie Ihre Leistungen nach Ihren individuellen Bedürfnissen',
    },
    {
      id: 2,
      title: 'Keine Gesundheitsfragen und Wartezeiten',
      text: 'Bei arbeitgebervollfinanzierter Variante',
    },
    {
      id: 3,
      title: 'Garantierte Mehrleistungen',
      text: 'Ergänzend zu den Leistungen der gesetztlichen Krankenversicherung',
    },
  ],
};
