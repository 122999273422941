import React from 'react';
import { Config, Item } from '../interfaces/Config';

interface UpsellingProps {
  config: Config;
}

function formatTarif(tarife: Item[]) {
  const mappedTarif = tarife.map((val) => val.id);
  return mappedTarif.join(';');
}

export default function UpsellingComponent({ config }: UpsellingProps): any {
  return (
    // @ts-ignore
    <vkb-bkv-upselling
      style={{ width: '100%' }}
      mandant={config.vertriebsweg}
      tarif={config.ausgangstarif.id}
      tarifefilter={formatTarif(config.tarife)}
      arbeitgeber={config.firmenname}
      vermittler={
        config.ansprechpartner.vorname + ' ' + config.ansprechpartner.nachname
      }
      email={config.kontakt_mindestanzahl}
      abc={`${process.env.REACT_APP_WEBCOMPONENT_API_KEY}`}
    />
  );
}
