import styled from "styled-components";
import { colors } from "../../Theme";

export const CustomButton = styled.button`
  color: white;
  cursor: pointer;
  background-color: unset;
  border: unset;

  svg {
    background: ${colors.darkGrey};
    border-radius: 50%;
    position: relative;
    bottom: 20px !important;
    padding: 5px;
  }
`;
