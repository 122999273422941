import PersonIcon from "@material-ui/icons/Person";
import React, { useState } from "react";
import styled from "styled-components";
import { Badge, Grid } from "@material-ui/core";
import { XSBreakPoint } from "../Theme";
import { Ansprechpartner } from "../interfaces/Config";

const Info = styled(Grid)`
  display: flex;
  text-align: right;
  padding: 0 20px 12px;
`;

const Text = styled.a`
  font-size: 16px;
  color: ${(props: any) => props.theme.partnerNameColor};
  font-weight: ${(props: any) => props.theme.partnerTitleWeight};
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;

const Link = styled.a`
  color: ${(props: any) => props.theme.partnerLinkColor};
  font-weight: ${(props: any) => props.theme.partnerLinkWeight};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;

const Titel = styled.h1`
  color: ${(props: any) => props.theme.partnerTitleColor};
  font-weight: ${(props: any) => props.theme.partnerTitleWeight};
  text-align: right;
  font-size: calc(1rem + ((1vw - 7.68px) * 0.5208));
  white-space: nowrap;
`;

const PartnerButton = styled(Badge)`
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  transition: 1.5s;
  visibility: visible;
  background: ${(props: any) => props.theme.badgeBackground};
  -webkit-box-shadow: 4px 4px 10px -3px #525252;
  box-shadow: 4px 4px 10px -3px #525252;
  position: absolute !important;
  top: -30px;
  left: -30px;
  z-index: 999 !important;

  @media (max-width: 989px) {
    top: -25px;
    left: -25px;
  }
  @media (max-width: 768px) {
    width: 50px !important;
    height: 50px !important;
    top: -20px;
    left: -20px;
  }
  @media (max-width: 650px) {
    width: 45px !important;
    height: 45px !important;
    top: -20px;
    left: -10px;
  }
  @media (max-width: 375px) {
    top: -20px;
    left: -20px;
  }

  .icon {
    align-self: center;
    margin: 0 auto;
    color: ${(props: any) => props.theme.partnerIconColor};
    font-size: 2.4em;

    @media (max-width: 1220px) {
      font-size: 2.5em;
    }
    @media (max-width: 768px) {
      font-size: 2em;
    }
    @media (max-width: 650px) {
      font-size: 1.9em;
    }
  }
`;

const Img = styled.img`
  align-self: center;
  width: 30px !important;
  height: 30px !important;
  margin-bottom: 3px;
  @media (max-width: 768px) {
    width: 25px !important;
    height: 25px !important;
  }
  @media (max-width: 375px) {
    margin: 0!important;
    width: 20px !important;
    height: 20px !important;
  }
`;

interface PartnerInfoProps {
  src?: string;
  ansprechpartner: Ansprechpartner;
  handleClick?: () => void,
}

export default function PartnerInfo({
  ansprechpartner,
  src,
}: PartnerInfoProps) {
  return (
    <>
      <PartnerButton>
        <PersonIcon className="icon" />
      </PartnerButton>
      <Info container direction="column">
        <Titel>Ihr Ansprechpartner</Titel>
        {/*  <Img src={src} alt="logo" />*/}
        {ansprechpartner?.firmenname && (
          <Text>{ansprechpartner.firmenname}</Text>
        )}
        <Text>
          {ansprechpartner.vorname} {ansprechpartner.nachname}
        </Text>
        <Text>
          {ansprechpartner.adresse.straße} {ansprechpartner.adresse.hausnummer}
        </Text>
        <Text>
          {ansprechpartner.adresse.plz} {ansprechpartner.adresse.ort}
        </Text>
        <Link href={`tel:${ansprechpartner.telefon}`}>
          {ansprechpartner.telefon}
        </Link>
        <Link href={`mailto:${ansprechpartner.mail}`}>
          {ansprechpartner.mail}
        </Link>
      </Info>
    </>
  );
}