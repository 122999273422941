import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { CustomButton } from "./style";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface CarouselProps {
  children: React.ReactNode[];
}

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  768: { items: 3 },
  1024: { items: 4 },
  1440: { items: 5 },
};
export default function Index({ children }: CarouselProps) {
    const renderNextButton = () => {
        return (
            <CustomButton>
                <ArrowForwardIosIcon style={{position: "absolute", right: -28, top: "47%"}}/>
            </CustomButton>
        );
    };

    const renderPrevButton = () => {
        return (
            <CustomButton>
                <ArrowBackIosNewIcon style={{position: "absolute", left: -28, top: "47%"}}/>
            </CustomButton>
        );
    };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ width: "100%", overflow: "hidden", paddingRight:"30px", paddingLeft:"30px"}}>
        <AliceCarousel
          disableDotsControls
          mouseTracking
          items={children}
          responsive={responsive}
          infinite
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
        />
      </div>
    </div>
  );
}
