import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import axios from 'axios';
// Import Variables
import { footerUKV, footerVKB } from './mocks/mockFooter';
import { BenefitsComponent } from './Components/BenefitsComponent';
import HeaderComponent from './Components/HeaderComponent';
import { FooterComponent } from './Components/FooterComponent';
import { Config } from './interfaces/Config';
import Partner from './Partials/Partner';
import IframeComponent from './Components/IframeComponent';
import { useRecoilState } from 'recoil';
import { ukv, vkb } from './Theme';
import { ukvData, vkbData } from './mocks/mockContent';
import CarouselComponent from './Components/CarouselComponent';
import { iframeAtom } from './atom/Atoms';
import Navbutton from './Partials/Navbutton/index';
import UKV_Logo from './assets/images/ukv_Logo.png';
import VKB_Logo from './assets/images/vkb_Logo.png';
import PageNotFound from './Partials/PageNotFound';

const Wrapper = styled(Grid)`
  margin: 0 auto;
  height: 100vh;
`;

const Module = styled(Grid)`
  width: 100%;
`;

const PartnerContainer = styled(Grid)`
  z-index: 1000;
`;

function App() {
  const [config, setConfig] = useState<Config>();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [iframe, setIFrame] = useRecoilState(iframeAtom);

  useEffect(() => {
    const curpath = window.location.pathname.split('/')[1];
    if (curpath !== 'Bildrechte') {
      const api = `${process.env.REACT_APP_BACKEND_BASE_URL}`;
      const path = `${api}getconfigfile?path=${
        window.location.pathname.split('/')[1]
      }`;

      axios
        .get(path, {
          headers: {
            'x-api-key': `${process.env.REACT_APP_BACKEND_APIKEY}`,
          },
        })
        .then((res) => {
          setConfig({ ...res.data });
          handleHeader(res.data.isukvdesign);
          setPageLoaded(true);
        })
        .catch((err) => {
          console.log(err);
          setPageLoaded(true);
        });
    }
  }, []);

  window.addEventListener('load', function (event) {
    let path = window.location.href;
    let index = path.indexOf(path.split('/')[4]);
    if (path.split('/')[4] && path.split('/')[4].length > 0) {
      window.history.pushState({}, '', path.substring(0, index - 1));
      this.location.reload();
    }
  });

  function handleHeader(isukvdesign: boolean) {
    const favicon = document.getElementById('favicon');
    //  @ts-ignore
    if (favicon) favicon.href = isukvdesign ? UKV_Logo : VKB_Logo;

    const title = document.getElementsByTagName('title')[0];
    title.innerHTML = isukvdesign
      ? 'Private Krankenversicherung | UKV'
      : 'Versicherungen, private Vorsorge, sichere Geldanlagen | Versicherungskammer Bayern';
  }

  return (
    <Router>
      <Switch>
        {config && pageLoaded ? (
          <ThemeProvider theme={config.isukvdesign ? ukv : vkb}>
            <Route exact path={`/${config.pathname}`}>
              {iframe ? (
                <div>
                  <Module>
                    <PartnerContainer>
                      <Partner
                        img={
                          config.isukvdesign
                            ? ukvData.partnerImg
                            : vkbData.partnerImg
                        }
                        ansprechpartner={config.ansprechpartner}
                        insideHeader={false}
                      />
                    </PartnerContainer>
                  </Module>

                  <Module item xs={12}>
                    <HeaderComponent config={config} />
                  </Module>
                  <Module
                    container
                    justifyContent="center"
                    style={{ margin: '3em 0' }}
                  >
                    <Navbutton
                      link={window.location.pathname}
                      text="Zurück zur Startseite"
                    />
                  </Module>
                  <Module>
                    <IframeComponent config={config} />
                  </Module>
                </div>
              ) : (
                <Wrapper>
                  <Module>
                    <PartnerContainer>
                      <Partner
                        img={
                          config.isukvdesign
                            ? ukvData.partnerImg
                            : vkbData.partnerImg
                        }
                        ansprechpartner={config.ansprechpartner}
                        insideHeader={false}
                      />
                    </PartnerContainer>
                  </Module>
                  <Module item xs={12}>
                    <HeaderComponent config={config} />
                  </Module>
                  <Module>
                    <CarouselComponent
                      config={config}
                      isukvdesign={config.isukvdesign}
                    />
                  </Module>
                  <Module
                    container
                    justifyContent="center"
                    style={{ margin: '4em 0 0em 0' }}
                  >
                    <Navbutton
                      onClick={() => setIFrame(true)}
                      text="Jetzt Versicherungsschutz erweitern"
                    />
                  </Module>
                  <Module item xs={12}>
                    <BenefitsComponent isukvdesign={config.isukvdesign} />
                  </Module>
                  <Module
                    container
                    justifyContent="center"
                    style={{ margin: '3em 0' }}
                  >
                    <Navbutton
                      onClick={() => setIFrame(true)}
                      text="Jetzt Versicherungsschutz erweitern"
                    />
                  </Module>
                  <Module item xs={12}>
                    <FooterComponent
                      {...(config.isukvdesign ? footerUKV : footerVKB)}
                    />
                  </Module>
                </Wrapper>
              )}
            </Route>
          </ThemeProvider>
        ) : (
          pageLoaded && <Route component={PageNotFound} />
        )}
      </Switch>
    </Router>
  );
}

export default App;
