/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { ukvData, vkbData } from "../mocks/mockContent";
import UpsellingComponent from "./UpsellingComponent";
import { Config } from "../interfaces/Config";
import {FooterComponent} from "./FooterComponent";
import {footerUKV, footerVKB} from "../mocks/mockFooter";

interface iFrameProps {
  config: Config;
}

const Wrapper = styled(Grid)`
  z-index: 99;
  margin-bottom: 20%;

  @media (max-width: 745px) {
    margin-bottom: 40%;
  }  
  @media (max-width: 670px) {
    margin-bottom: 50%;
  }
  @media (max-width: 540px) {
    margin-bottom: 60%;
  } 
  @media (max-width: 450px) {
    margin-bottom: 70%;
  } 
  @media (max-width: 390px) {
    margin-bottom: 82%;
  }
  
  iframe {
    width: 100vw !important;
    border: unset;
  }
`;

const Title = styled(Grid)`
  font-size: ${(props: any) => props.theme.fontSizeTitle};
  width: 100%;
  margin: 50px 0px;
  text-align: center;
  color: ${(props: any) => props.theme.titleColor};
  font: ${(props: any) =>
    `normal normal ${props.theme.textStaerke}22px/32px${props.theme.textFontFamily}`};
  font-weight: ${(props: any) => props.theme.textStaerke};
`;

export default function IframeComponent({ config }: iFrameProps) {
  return (
      <>
    <Wrapper container direction="column">
      <Title>
        {config.isukvdesign ? ukvData.iFrameTitle : vkbData.iFrameTitle}
      </Title>
      <Grid item xs={12}>
        <UpsellingComponent config={config} />
      </Grid>

    </Wrapper>
        <FooterComponent
            {...(config.isukvdesign ? footerUKV : footerVKB)}
        />
      </>
  );
}
