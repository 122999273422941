import React from "react";
import { Config } from "../../interfaces/Config";
import { ukvData, vkbData } from "../../mocks/mockContent";
import { UKVCarouselData } from "../../mocks/mockSliderUKV";
import { VKBCarouselData } from "../../mocks/mockSliderVKB";
import Carousel from "../../Partials/Carousel";
import { chooseIcon } from "./helper";
import {
  CardText,
  Container,
  Icon,
  IconBackground,
  IconWrapper,
  StyledTile,
  Subtitle,
  Title,
  Text,
  CustomCardContent,
} from "./style";

interface CarouselComponentProps {
  isukvdesign: boolean;
  config?: Config;
}

interface Card {
  id: number;
  subtitle: any;
  text: string;
  icon: string;
}

export default function Index({ isukvdesign, config }: CarouselComponentProps) {
  function getselectedTarifList(): Card[] {
    return isukvdesign ? UKVCarouselData.cards : VKBCarouselData.cards;
  }
  return (
    <Container>
      <Title>
        {isukvdesign ? ukvData.sliderTitle : vkbData.sliderTitle}{" "}
        {config?.firmenname}
      </Title>
      <Subtitle>
        {isukvdesign ? ukvData.sliderSubtitle : vkbData.sliderSubtitle}
      </Subtitle>
      <Text>{isukvdesign ? ukvData.sliderText : vkbData.sliderText}</Text>
      {config && (
        <Carousel>
          {getselectedTarifList().map((card: Card) => (
            <StyledTile key={card.id}>
              <IconWrapper container justifyContent="center">
                <IconBackground>
                  <Icon src={chooseIcon(card, isukvdesign)} />
                </IconBackground>
              </IconWrapper>
              <CustomCardContent>
                {card.subtitle}
                <CardText>{card.text}</CardText>
              </CustomCardContent>
            </StyledTile>
          ))}
        </Carousel>
      )}
    </Container>
  );
}
