import aerztin from './Aerztin.webp';
import baeckerin from './Baeckerin.webp';
import bauleiter from './Bauleiter.webp';
import buero from './Buero.webp';
import einzelhandel from './Einzelhandel.webp';
import studium from './Studium.webp';

export default {
  'Ärztin': aerztin,
  'Bäckerin': baeckerin,
  'Bauleiter*in': bauleiter,
  'Buero': buero,
  'Einzelhandel': einzelhandel,
  'Studium': studium,
};
