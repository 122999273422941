import { sliderObj } from "../interfaces/sliderObj";
import { CardSubtitle } from "../Components/CarouselComponent/style";
import React from "react";

export const UKVCarouselData: sliderObj = {
  title: "Optionen für Ihre betriebliche Krankenversicherung der Musterfirma",
  cards: [
    {
      id: 1,
      subtitle: <CardSubtitle>Krankenhaus&shy;aufenthalt</CardSubtitle>,
      text: "Chefarzt/Spezialist, 1- oder 2-Bettzimmer",
      icon: "icon-krankenhaus",
    },
    {
      id: 2,
      subtitle: <CardSubtitle>Zahnersatz</CardSubtitle>,
      text: "Leistungen für Zahnersatz und Implantate",
      icon: "icon-zahnersatz",
    },
    {
      id: 3,
      subtitle: <CardSubtitle>Alternative Heilmethoden</CardSubtitle>,
      text: "Beim Heilpraktiker, Arzt für Naturheilverfahren oder Osteopathen",
      icon: "icon-alternative-heilmethoden",
    },
    {
      id: 4,
      subtitle: <CardSubtitle>Sehhilfen</CardSubtitle>,
      text: "Brillen und Kontaktlinsen",
      icon: "icon-sehhilfen",
    },
    {
      id: 5,
      subtitle: <CardSubtitle>Vorsorge</CardSubtitle>,
      text: "Schutzimpfungen, Vorsorgeuntersuchungen, Hörhilfen",
      icon: "icon-vorsorge",
    },
    {
      id: 6,
      subtitle: <CardSubtitle>Auslandsreise</CardSubtitle>,
      text: "Private und dienstliche Reisen",
      icon: "icon-reise",
    },
  ],
};
