import krankenhausUKV from "../../assets/icon-krankenhaus-ukv.svg";
import krankenhausVKB from "../../assets/icon-krankenhaus-vkb.svg";
import zahnersatzUKV from "../../assets/icon-zahnersatz-ukv.svg";
import zahnersatzVKB from "../../assets/icon-zahnersatz-vkb.svg";
import alternativeHeilmethodenUKV from "../../assets/icon-alternative-heilmethoden-ukv.svg";
import alternativeHeilmethodenVKB from "../../assets/icon-alternative-heilmethoden-vkb.svg";
import sehhilfenUKV from "../../assets/icon-sehhilfen-ukv.svg";
import sehhilfenVKB from "../../assets/icon-sehhilfen-vkb.svg";
import vorsorgeUKV from "../../assets/icon-vorsorge-ukv.svg";
import vorsorgeVKB from "../../assets/icon-vorsorge-vkb.svg";
import reiseUKV from "../../assets/icon-reise-ukv.svg";
import reiseVKB from "../../assets/icon-reise-vkb.svg";
import { Card } from "../../interfaces/Card";

export function chooseIcon(card: Card, isukvdesign: boolean) {
  switch (card.icon) {
    case "icon-krankenhaus":
      return isukvdesign ? krankenhausUKV : krankenhausVKB;
    case "icon-zahnersatz":
      return isukvdesign ? zahnersatzUKV : zahnersatzVKB;
    case "icon-alternative-heilmethoden":
      return isukvdesign
        ? alternativeHeilmethodenUKV
        : alternativeHeilmethodenVKB;
    case "icon-sehhilfen":
      return isukvdesign ? sehhilfenUKV : sehhilfenVKB;
    case "icon-vorsorge":
      return isukvdesign ? vorsorgeUKV : vorsorgeVKB;
    case "icon-reise":
      return isukvdesign ? reiseUKV : reiseVKB;
    default:
      return "null";
  }
}
