import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { XSBreakPoint } from '../Theme';

const Wrapper = styled(Grid)`
  margin: 50px;
  font-size: ${(props: any) => props.theme.fontSizeTitle};
  @media (max-width: ${XSBreakPoint}){
    font-size: ${(props: any) => props.theme.fontSizeTitleSmall};
  }
`;

 function PageNotFound() {
  return (
    <Wrapper container>
      <div>
        <h1>404 - Diese Firma existiert nicht!</h1>
      </div>
    </Wrapper>
  );
}
export default PageNotFound;
