import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { SMBreakPoint, XSBreakPoint } from "../Theme";

interface FooterProps {
  links: { to: string; text: string }[];
  copyright: string;
}

const Wrapper = styled(Grid)`
  background-color: ${(props: any) => props.theme.footerBackground};
  margin: 0 auto;
  min-height: 73px;
  @media (max-width: ${XSBreakPoint}) {
    min-height: 79px;
  }
`;

const DisplayWrapper = styled(Grid)`
  margin: 0 auto;
  display: block;
  @media (max-width: ${SMBreakPoint}) {
    display: none !important;
  }
`;

const InnerWrapper = styled(Grid)`
  width: 1020px !important;
  margin: 0 auto;
`;

const MobileWrapper = styled(Grid)`
  display: none !important;
  @media (max-width: ${SMBreakPoint}) {
    display: flex !important;
    margin: 15px 30px !important;
  }
`;

const LinkContainer = styled(Grid)`
  display: flex;
  position: relative;
  left: -8px;
  align-items: center;
`;

const Links = styled(Grid)``;

const StyledLinks = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  border-left: 1px solid ${(props: any) => props.theme.textColorFooter};
`;

const URL = styled.a`
  text-decoration: none;
  list-style: none;
  font-size: ${(props: any) => props.theme.fontSizeFooter};
  color: ${(props: any) => props.theme.textColorFooter};
  font-weight: ${(props: any) => props.theme.textWeightFooter};
  border-right: 1px solid ${(props: any) => props.theme.textColorFooter};
  padding: 0px 8px;
  &:last-child {
  }
`;

const Copy = styled(Grid)`
  margin-top: 15px !important;
  color: ${(props: any) => props.theme.textColorBlack};
  font-size: ${(props: any) => props.theme.fontSizeFooter};
  font-weight: ${(props: any) => props.theme.textWeightFooter};
`;

export function FooterComponent({ links, copyright }: FooterProps) {
  return (
    <Wrapper container justifyContent="center">
      <DisplayWrapper container item md={10}>
        <InnerWrapper container justifyContent="center">
          <Links
            container
            item
            md={8}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {links.map((element, index) => (
              <LinkContainer item key={index}>
                {element.to.length > 4 ? (
                  <URL href={element.to} target="_blank">
                    {" "}
                    {element.text}{" "}
                  </URL>
                ) : (
                  <StyledLinks className="link" title={element.text}>
                    {" "}
                    {element.text}{" "}
                  </StyledLinks>
                )}
              </LinkContainer>
            ))}
          </Links>
          {copyright && (
            <Copy
              container
              item
              md={4}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {copyright}
            </Copy>
          )}
        </InnerWrapper>
      </DisplayWrapper>

      <MobileWrapper container item xs={12} direction="row" alignItems="center">
        <Links
          container
          item
          xs={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {links.map((element, index) => (
            <LinkContainer item key={index}>
              {element.to.length > 4 ? (
                <URL href={element.to} target="_blank">
                  {" "}
                  {element.text}{" "}
                </URL>
              ) : (
                <StyledLinks className="link" title={element.text}>
                  {" "}
                  {element.text}{" "}
                </StyledLinks>
              )}
            </LinkContainer>
          ))}
        </Links>
        {copyright && (
          <Copy
            container
            item
            xs={12}
            direction="row"
            justifyContent="flex-start"
          >
            {copyright}
          </Copy>
        )}
      </MobileWrapper>
    </Wrapper>
  );
}
